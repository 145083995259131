<template>
  <div class="main">
    <!-- 头部 -->
    <div class="main-step box-shadow-default">
      <!-- 步骤条 -->
      <el-steps align-center :active="activeIndex">
        <el-step v-for="(item, i) in currentSelecte" :key="i" :title="item.title"></el-step>
      </el-steps>
    </div>
    <!-- 申请内容 -->
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'apply',
  data() {
    return {
      render: 1,
    };
  },
  computed: {
    // 激活索引
    ...mapState('wysq-step', ['activeIndex', 'currentSelecte']),
    // 填写申请表基本参数
    ...mapState('wysq-data', ['firstFlow']),
  },

  methods: {
    ...mapMutations('wysq-step', ['targetToStep', 'getStepList']),
  },
  created() {
    if (!this.firstFlow.fourTypeData && !this.$route.params.ywh) {
      this.$router.push({
        name: 'check-in',
      });
      return;
    }
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/public';
@import 'src/pages/iebdc/styles/common-variables';
.main {
  .main-step {
    margin-bottom: 40px;
    border-radius: $border-radius-base;
    background-color: $background-color-layout;
    /deep/ .flex-space-between {
      padding: $spacing-base $spacing-medium;
    }
    /deep/ .el-steps--horizontal {
      padding: $spacing-large 0 $spacing-large 0;
    }
  }
}
</style>
